import React, { useState } from 'react';
import { FaTools, FaBook, FaProjectDiagram, FaUsers, FaLightbulb, FaUserGraduate } from 'react-icons/fa';
import videoFile from './videos/Completefullstack.mp4';
import SEO from '../SEO';

import CompletefullStackSyllabus from './CompletefullStackSyllabus';
import EnrollmentForm from './EnrollmentForm';
import Timer from './Timer';


import CourseTestimonial from './CourseTestimonial';

const CompletefullStack = () => {
    const [activeTab, setActiveTab] = useState('learningOutcomes');
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [price, setPrice] = useState(13000); // Default price
    const [couponCode, setCouponCode] = useState('');
    const [notification, setNotification] = useState('');
    const courseTitle = 'Complete Full Stack Development'
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const [isValidCoupon, setIsValidCoupon] = useState(true);
    const [isTimerActive, setIsTimerActive] = useState(false);   


    const coupons = {
        TRYNEW: 0.08, // 8% discount
        trynew: 0.08, // 8% discount
        VAGAYARA2024: 0.16, // 16% discount
        vagayara2024:0.16 ,
    };
    

    const handleCouponApply = () => {
        const discount = coupons[couponCode.toUpperCase()];

        if (discount) {
            setPrice(13000 * (1 - discount));
            setNotification(`Coupon applied successfully! You got ${discount * 100}% discount.`);
            setIsCouponApplied(true);
            setIsValidCoupon(true);
            setIsTimerActive(true); // Start the timer
        } else {
            setNotification('Invalid Coupon Code');
            setIsValidCoupon(false);
        }
    };


    const handleEnrollClick = () => {
        setIsFormOpen(true); // Open enrollment form
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };


    return (
        <div>
            <SEO
                title="Complete Full Stack Development - Divine Infotech"
                description="Master Complete Full Stack Development with HTML, CSS, JavaScript, and backend technologies to build dynamic web applications."
                keywords="Full Stack Web Development, HTML, CSS, JavaScript, Backend Technologies, Divine Infotech"
            />
            <div className="bg-gray-200 text-gray-900">
                <div className="bg-gray-50 text-gray-900">
                    {/* Header Section */}
                    <header className="bg-gradient-to-r from-blue-700 to-blue-900 text-white py-10">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <h1 className="text-4xl font-bold mb-2">Complete Full Stack Development</h1>
                            <p className="md:text-lg text-sm text-gray-300">Become a proficient full stack developer</p>
                        </div>
                    </header>

                    {/* Video Section */}
                    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                        <h2 className="text-3xl font-semibold mb-4">Introduction</h2>
                        <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-8 gap-5 md:gap-10">
                            <div className="relative lg:w-2/3">
                                <div style={{ paddingBottom: '56.25%', position: 'relative', height: 0 }}>
                                    <video controls className="absolute top-0 left-0 w-full h-full object-cover border-2 border-blue-400 rounded-md shadow-lg">
                                        <source src={videoFile} type="video/mp4" />
                                    </video>
                                </div>
                            </div>

                            {/* Course Highlights */}
                            <div className="mt-8 lg:mt-0 lg:w-1/3 flex flex-col justify-between bg-white p-8 rounded-md shadow-lg border border-blue-400">
                                <div>
                                    <h3 className="text-2xl font-semibold mb-6">Course Highlights</h3>
                                    <ul className="space-y-4 text-gray-800">
                                        <li className="flex items-start">
                                            <FaLightbulb className="text-2xl text-blue-600 mr-3" />
                                            <p>Comprehensive curriculum from basics to advanced concepts</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaProjectDiagram className="text-2xl text-blue-600 mr-3" />
                                            <p>Hands-on projects and real-world application development</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaUsers className="text-2xl text-blue-600 mr-3" />
                                            <p>Expert guidance and support</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaUsers className="text-2xl text-blue-600 mr-3" />
                                            <p>Access to a community of fellow learners and professionals</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaTools className="text-2xl text-blue-600 mr-3" />
                                            <p>Career advice and portfolio building</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="flex flex-col pl-6 md:pl-60 gap-3">
                        <span className="text-xl md:text-2xl font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-700 px-4 py-2 rounded-lg shadow-md w-fit">
                            Fee: <span className='text-yellow-400'>₹{price}</span>
                        </span>
                        <span className="text-xl md:text-2xl font-semibold text-gray-800">
                            Duration: <span className="text-blue-600">50 days</span>
                        </span>
                    </div>


                    <div className="flex flex-col md:pl-60 pl-6 md:w-2/4 mt-5">
                        <input
                            type="text"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder="Enter Coupon Code"
                            className="mb-2 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                        <button
                            onClick={handleCouponApply}
                            className="font-medium py-2 px-4 rounded-md text-lg bg-blue-700 text-white border border-blue-700 transition-all hover:bg-white hover:text-blue-700 hover:shadow-md"
                        >
                            Apply Coupon
                        </button>
                        {notification && (
                            <div className={`mt-4 p-2 text-center rounded-md ${notification.includes('Invalid') ? 'bg-red-100 text-red-500' : 'bg-green-100 text-green-500'} shadow-sm`}>
                                {notification}
                            </div>
                        )}

                        {/* timer */}
                        {isCouponApplied && isTimerActive && (
                            <div className="mt-4 bg-gray-100 p-4 rounded-md text-center">
                                <p className="text-lg font-semibold">Hurry up! Offer expires in:</p>
                                <Timer
                                    initialTime={300} // 5 minutes
                                    onExpire={() => setIsTimerActive(false)}
                                />
                                <button
                                    className="bg-red-600 text-white py-2 px-4 mt-2 rounded-md transition-all duration-300 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    onClick={handleEnrollClick}
                                >
                                    Grab offer now
                                </button>
                            </div>
                        )}



                    </div>

                    <div className='md:pl-60 pl-6 flex flex-col'>
                        <button
                            onClick={handleEnrollClick}
                            className='font-medium py-3 px-6 rounded-md text-lg bg-blue-800 text-white border border-blue-800 transition-all hover:bg-white hover:text-blue-800 hover:shadow-md mt-5 flex items-center'
                            style={{ width: 'fit-content' }}
                        >
                            Enroll Now <FaUserGraduate className="ml-2 text-lg" />
                        </button>
                        <p className="text-sm text-gray-700 mt-2">2/5 students enrolled in this batch</p>
                        <p className="text-sm text-red-600 mt-2 font-semibold">HURRY UP! Only 3 slots available.</p>
                    </div>

                    {/* Syllabus FAQ */}
                    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                        <CompletefullStackSyllabus />
                    </section>

                    {/* Additional Sections */}
                    <div>
                        {/* Buttons for switching content */}
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-center space-x-4">
                            <button
                                onClick={() => setActiveTab('learningOutcomes')}
                                className={`px-4 py-2 font-semibold rounded-lg transition-all ${activeTab === 'learningOutcomes' ? 'bg-blue-700 text-white' : 'bg-white text-blue-700 border border-blue-700'}`}
                            >
                                Learning Outcomes
                            </button>
                            <button
                                onClick={() => setActiveTab('keyFeatures')}
                                className={`px-4 py-2 font-semibold rounded-lg transition-all ${activeTab === 'keyFeatures' ? 'bg-blue-700 text-white' : 'bg-white text-blue-700 border border-blue-700'}`}
                            >
                                Key Features
                            </button>
                        </div>

                        {/* Learning Outcomes Section */}
                        {activeTab === 'learningOutcomes' && (
                            <section className="bg-gray-100 py-12">
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <h2 className="text-2xl md:text-3xl font-semibold mb-6">Learning Outcomes</h2>
                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <FaBook className="text-2xl text-green-600 mr-4" />
                                            <p>Establish a strong foundation in web development essentials.</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaBook className="text-2xl text-green-600 mr-4" />
                                            <p>Develop proficiency in creating dynamic and responsive front-end interfaces.</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaBook className="text-2xl text-blue-600 mr-4" />
                                            <p>Gain expertise in server-side programming and database management.</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaBook className="text-2xl text-teal-600 mr-4" />
                                            <p>Integrate front-end and back-end to build complete web applications.</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaBook className="text-2xl text-gray-800 mr-4" />
                                            <p>Master advanced concepts and deploy your applications.</p>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        )}

                        {/* Key Features Section */}
                        {activeTab === 'keyFeatures' && (
                            <section className="bg-gray-100 py-12">
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <h2 className="text-2xl md:text-3xl font-semibold mb-6">Key Features</h2>
                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <FaLightbulb className="text-2xl text-blue-600 mr-4" />
                                            <p>Hands-on projects and real-world application development</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaProjectDiagram className="text-2xl text-blue-600 mr-4" />
                                            <p>Expert guidance and support</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaUsers className="text-2xl text-blue-600 mr-4" />
                                            <p>Access to a community of fellow learners and professionals</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaUsers className="text-2xl text-blue-600 mr-4" />
                                            <p>Personal career advice and portfolio building</p>
                                        </li>
                                        <li className="flex items-start">
                                            <FaTools className="text-2xl text-blue-600 mr-4" />
                                            <p>Flexible learning schedule with online access anytime</p>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        )}
                    </div>

                    <CourseTestimonial />

                    {isFormOpen && (
                        <EnrollmentForm onClose={handleCloseForm} courseName={courseTitle} price={price} appliedCoupon={couponCode} />
                    )}
                </div>
            </div></div>
    );
};

export default CompletefullStack;
